<template>
    <vue-tinymce v-model="value" :setting="setting" @change="change" />
</template>

<script>
  import 'tinymce/skins/content/default/content.min.css'
  import 'tinymce/skins/ui/oxide/skin.min.css'
  import 'tinymce/skins/ui/oxide/content.min.css'

  //主题
  import 'tinymce/themes/silver'

  //插件
  import 'tinymce/plugins/link' //链接插件
  import 'tinymce/plugins/image' //图片插件
  import 'tinymce/plugins/imagetools'
  import 'tinymce/plugins/table' //表格插件
  import 'tinymce/plugins/lists' //列表插件
  import 'tinymce/plugins/advlist' //列表插件
  import 'tinymce/plugins/quickbars' //快速栏插件
  import 'tinymce/plugins/fullscreen' //全屏插件
  import 'tinymce/plugins/preview'
  import 'tinymce/plugins/searchreplace'

  import {uploadURL, imageURL, imagetools_cors_hosts} from '../../../utils/config'
  import axios from 'axios'

  import 'tinymce/icons/default/icons'
  export default {
    name: "tinymceEditor",
    props: {
      content: {
        type: String,
        default: ''
      }
    },
    data(){
      return {
        setting: {
          language_url: './tinymce/zh_CN.js',
          language:'zh_CN',
          menubar: false,
          toolbar: [
            'newdocument | undo redo | fullscreen | removeformat | bold italic underline strikethrough | formatselect fontselect fontsizeselect lineheight forecolor backcolor alignleft aligncenter alignright alignjustify | link unlink | numlist bullist | image table | indent outdent | superscript subscript | cut copy paste | searchreplace preview'
          ],
          plugins: "link image imagetools table lists advlist fullscreen quickbars preview searchreplace",
          height: 500,
          content_style: "img {max-width:100%;}",
          branding: false,
          toolbar_drawer: false,
          imagetools_cors_hosts: [imagetools_cors_hosts],
          imagetools_credentials_hosts: [imagetools_cors_hosts],
          font_formats: '微软雅黑=Microsoft YaHei,Helvetica Neue; 黑体=黑体; 宋体=宋体; 仿宋=仿宋; 楷体=楷体; 华文行楷=华文行楷; 苹果苹方=PingFang SC,Microsoft YaHei,sans-serif; Terminal=terminal;',
          fontsize_formats: '12px 14px 16px 18px 20px 22px 24px 26px 28px 30px 32px 36px 48px 56px',
          lineheight_formats: "1 1.2 1.4 1.6 1.8 2 2.2 2.4 2.6 2.8 3 3.2 3.4 3.6 3.8 4",
          images_upload_handler: function (blobInfo, succFun, failFun) {
            let formData = new FormData();
            let file = blobInfo.blob();
            formData.append('file', file, file.name)

            axios({
              method: 'POST',
              url: uploadURL,
              timeout: 30000,
              headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('access_token_shop'),
              },
              data: formData,
            }).then((response) => {
              const res = response.data
              if (response.status === 200) {
                if (res.code === 0) {
                  succFun(imageURL + res.data)
                } else {
                  failFun('图片上传失败')
                }
              } else {
                failFun('图片上传失败')
              }
            }).catch(() => {
              failFun('图片上传失败')
            })
          }
        }
      }
    },
    computed: {
      value: {
        get () {
          return this.content
        },
        set (v) {
          this.$emit('changeValue', v)
        }
      }
    },
    methods: {
      change(editor) {
        this.$emit('changeValue', editor)
      }
    },
    components: {}
  }
</script>
